import React from 'react';
import { Center, Heading, Icon, Text } from '@chakra-ui/react';
import { ArrowElbowLeft } from 'phosphor-react';

import { BaseLink, BaseScene, BaseSeo } from '@components/base';

export default function NotFoundPage() {
  const HomeLink = React.useCallback(
    () => (
      <Center>
        <Icon as={ArrowElbowLeft} mr={2} w='24px' h='24px' weight='bold' />
        <Text as='span' fontSize='lg'>
          back to home
        </Text>
      </Center>
    ),
    []
  );

  return (
    <BaseScene as='section'>
      <Center pt={12} flexDir='column'>
        <Heading as='h1' lineHeight={2} size='lg'>
          Page Does Not Exist
        </Heading>
        <BaseLink to='/'>
          <HomeLink />
        </BaseLink>
      </Center>
    </BaseScene>
  );
}

export function Head() {
  return <BaseSeo title='Page Not found' />;
}
